.intro-container {
  display: grid;
  grid-template-columns: repeat(300px, 1fr);
  max-width: 1262px;
  padding: 120px 20px;
  align-items: center;
  margin: 0 auto;
}
.intro-container .left-side {
  gap: 30px;
  grid-column: 1/2;
  max-width: 670px;
}
.intro-container .left-side h1 {
  font-size: 4rem;
  line-height: 119%;
  color: var(--color-c4);
  font-weight: 800;
  padding-bottom: 60px;
}
.intro-container .left-side h1 span {
  color: var(--color-p2);
}
.intro-container .left-side p {
  font-size: 20px;
  line-height: 120%;
  font-weight: 600;
  color: var(--color-c3);
  max-width: 500px;
  padding-bottom: 60px;
}
.intro-container .left-side p span {
  font-weight: 800;
}
.intro-container .right-side {
  grid-column: 3/4;
}
.intro-container .right-side img {
  border-radius: 5px;
}

@media screen and (max-width: 992px) {
  .intro-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 60px;
    gap: 30px;
  }
  .intro-container .left-side {
    grid-column: 1/2;
    max-width: 660px;
  }
  .intro-container .left-side h1 {
    font-size: 40px;
    line-height: 125%;
    padding-bottom: 36px;
  }
  .intro-container .left-side p {
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 12px;
  }
  .intro-container .right-side {
    grid-column: 2/4;
  }
  .intro-container .right-side img {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 5px;
  }
}
@media screen and (max-width: 768px) {
  .intro-container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
  }
  .intro-container .left-side {
    grid-column: 1/2;
  }
  .intro-container .left-side h1 {
    font-size: 40px;
    line-height: 125%;
    padding-bottom: 36px;
  }
  .intro-container .left-side p {
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 36px;
  }
  .intro-container .right-side {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    grid-column: 1/2;
  }
  .intro-container .right-side img {
    width: 400px;
    height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 5px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .intro-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .intro-container .left-side {
    grid-column: 1/4;
    max-width: 660px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .intro-container .left-side h1 {
    font-size: 40px;
    line-height: 125%;
    padding-bottom: 12px;
    text-align: start;
    font-size: 2rem;
    padding-bottom: 30px;
  }
  .intro-container .left-side p {
    font-size: 1rem;
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 0px;
    text-align: start;
    padding-bottom: 18px;
  }
  .intro-container .right-side {
    width: 100%;
    grid-column: 1/4;
  }
  .intro-container .right-side img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 5px;
  }
}/*# sourceMappingURL=styles.css.map */