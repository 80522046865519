.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 300px;
  border-radius: 5px;
  padding: 24px 48px;
  box-shadow: 0px 16px 47px -18px rgb(218, 218, 218);

  .info-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 40px;
    }

    h1 {
      color: var(--color-c4);
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      max-width: 250px;
      padding-bottom: 24px;

      span {
        font-weight: bold;
      }
    }
  }

  .price {
    h3 {
      font-size: 1rem;
      color: var(--color-c2);
      text-align: center;
      font-weight: 500;
      padding-bottom: 12px;
      line-height: 1.5rem;
      
      span {
        color: var(--color-p3);
        background: #eec3c3;
        padding: 2px;
        border-radius: 5px;
        font-weight: 800;
      }
    }

    h6 {
      padding-top: 8px;
      font-weight: 400;
      text-align: center;
      color: var(--color-c2);
    }
  }
}

@media screen and (max-width: 600px) {
  .box-info {
    max-width: 100%;
    padding: 12px 0;
    height: 250px;
    margin-bottom: 12px;

    .info-card {
      h1 {
        font-size: 1.25rem;
        padding-bottom: 8px;
        max-width: 150px;
      }

    }
    
    .price {
      button {
        padding: 6px 12px;
        font-size: 0.85rem;
        gap: 6px;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .box-info {
    max-width: 100%;
    padding: 12px 4px;
  }
}