.not-found--page {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 3rem;
    color: var(--color-c3);
    font-weight: 500;
  }
}