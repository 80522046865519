.bg-container {
  background: var(--color-w1);
  box-shadow: inset 0 40px white, inset 0 -40px white;
}

.about-container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  max-width: 1262px;
  margin: 0 auto;
  padding: 120px 20px;

  .left-side {
    grid-column: 1/2;
    display: flex;

    img {
      width: 100%;
      align-self: start;
    }

    ::after {
      content: '';
      position: absolute;
      display: inline-block;
      right: 0;
      top: 0;
      width: 30px;
      height: 4px;
      background: var(--color-p3);
    }
  }

  .right-side {
    grid-column: 2/4;

    h1 {
      font-size: 3rem;
      line-height: 3.5rem;
      color: var(--color-c3);
      font-weight: 800;
      padding-bottom: 30px;

      span {
        color: var(--color-p2);
      }
    }

    p {
      font-size: 18px;
      line-height: 28px;
      color: var(--color-c3);
      padding-bottom: 12px;

      span {
        font-weight: 800;
      }
    }

    .info-about--container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 30px 34px;
      background: var(--color-white);
      border-radius: 5px;
      gap: 32px;
      margin-top: 30px;

      .box {
        width: calc(50% - 32px);
        h1 {
          font-size: 18px;
          line-height: 120%;
          padding-bottom: 8px;
        }
  
        p {
          font-size: 14px;
          line-height: 20px;
          color: var(--color-c3);
          max-width: 217px;
          padding-bottom: 0;
        }

        svg {
          color: var(--color-p2);
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .bg-container {
    box-shadow: 0 0 0, 0 0 0;
    padding: 20px 0px;
  }
  .about-container {
    grid-template-columns: minmax(300px, 400px) 1fr;
    gap: 20px;
    padding: 60px 20px;

    .left-side {
      grid-column: 1/2;
    }

    .right-side {
      grid-column: 2/4;

      h1 {
        padding-bottom: 24px;
        font-size: 40px;
        line-height: 120%;
      }

      p {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .about-container {
    grid-template-columns: 1fr;

    .left-side, .right-side {
      grid-column: 1/2;
    }

    .left-side {
      max-width: 100%; 
      display: flex;

      
      justify-content: center;
      align-items: center;
      
      img {
        max-width: 50%;
        border-radius: 5px;
        object-fit: cover;
        padding-bottom: 24px;
      }
    }

    .right-side {
      flex-wrap: wrap;
      h1, p {
        text-align: center;
      }

      .info-about--container {
        gap: 14;
        .box {
          width: 45%;
          h1, p {
            text-align: start;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .about-container {
    .left-side {
      width: 100%;
    }
    .right-side {
      .info-about--container {
        .box {
          width: 100%;
        }
      }
    }
  }
}