.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 20px;
  max-width: 1262px;


  .left-side {
    max-width: 250px;
  }
  
  .right-side {

    .hidden, .showed {
      display: none;
      color: var(--color-w4);
    }

    nav {
      ul {
        display: flex;
        gap: 40px;
        align-items: center;
  
        li {
          position: relative;
        }

        li a {
          display: flex;
          align-items: center;
          color: var(--color-c3);
          font-weight: 500;
        }

        #icon {
          img, svg {
            color: var(--color-p2)
          }
        }

        .menu {
          position: absolute;
          right: 0;
          top: 40px;
          border-radius: 5px;
          background: var(--color-w1);
          display: none;
          transition: all 0.3s ease-in;

          a {
            padding: 0 12px;
            border-radius: 4px;
          }

          :hover {
            background: var(--color-p2);
            color: var(--color-w1);
          }
        }

        #active {
          display: inline-block;
        }
      }
    }
    

    #menu-icon {
      display: none;
    }
  }

}

@media screen and (max-width: 768px) {
  .header-container {
    position: relative;

    .left-side {
      img {
        max-width: 200px;
      }
    }

    .right-side {
      nav {
        transition: all 0.3s ease-in;
        
        ul {
          display: none;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        
        .active {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100vw;
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: none;
          z-index: 9;
          background: var(--color-p3);
          z-index: 80;
          color: var(--color-w1);

          li a {
            color: var(--color-w1);
            padding: 6px 12px;
            border-radius: 4px;
            background: var(--color-p4);
          }

          .menu {
            background: var(--color-p4);
            margin: 0 auto;
          }
        }

        .hidden {
          display: none;
        }

        .showed {
          display: inline-block;
          position: absolute;
          top: 30px;
          right: 20px;
        }
        
        #menu-icon {
          display: inline-block;
          z-index: 99;
        }

      }
    }

  }
}