.container-page--product {
  max-width: 1262px;
  padding: 80px 20px;
  display: grid;
  grid-template-columns: 1fr 450px;
  gap: 60px;
  margin: 0 auto;

  .left-side {
    .info-product {
      h1 {
        font-size: 2rem;
        font-weight: 600;
        padding-bottom: 8px;

        span {
          color: var(--color-p2);
          font-weight: 800;
        }
      }

      .bradcumb {
        padding-bottom: 80px;
        display: flex;
        gap: 24px;
        align-items: center;

        a {
          color: black;
          transition: all 0.3s ease-in-out;
        }

        a:hover {
          color: var(--color-p1);
        }

        #link {
          color: var(--color-p2);
          font-weight: bold;
        }

        svg, img {
          max-width: 12px;
          color: var(--color-p2);
        }
      }
    }

    .cta-info {
      display: flex;
      align-items: center;
      padding-bottom: 24px;
      gap: 30px;

      .img-cta {
        border-radius: 100%;
        padding: 12px;
        background: var(--color-w2);

        img {
          width: 30px;
          height: 30px;
        }
      }

      button {
        display: none;
      }
    }

    .box-container--cta {
      width: 100%;
      height: 200px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      .box-cta {
        border-radius: 5px;
        background: var(--color-w1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px;

        img, svg {
          max-width: 34px;
          margin-bottom: 24px;
          color: var(--color-p2);
        }

        h1 {
          font-size: 1.2rem;
          text-align: center;
          padding-bottom: 12px;
        }

        p {
          font-size: 0.85rem;
          text-align: center;
        }
      }

    }
    
    .text {
      overflow: hidden;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      margin: 120px 0 14px 0;
    }
    
    .active {
      transition: all 0.6s ease-in-out;
      -webkit-line-clamp: unset;
      display: block;
    }

    h3 {
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
      color: var(--color-p2);
      transition: all 0.3s ease-in;
      display: inline-block;
    }

    h3:hover {
      color: var(--color-p1);
    }

    .container-img {
      border-radius: 5px;
      margin-top: 30px;
      
      
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
        height: 100%;
      }
    }

    .container-img {
      width: 100%;
      height: 400px;
      background: var(--color-w2);
      border-radius: 5px;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;

    .box {
      width: 100%;
      display: flex;
      border-radius: 5px;
      background: var(--color-w1);
      position: sticky;
      position: -webkit-sticky;
      top: 60px;
      transition: all 0.3s ease-in-out;

      .particular-info {
        width: 100%;
        justify-self: flex-end;
        border-radius: 5px 5px 0 0;
  
        h1 {
          text-align: center;
          font-size: 1rem;
          font-weight: 400;
          padding: 12px 0;
          background: var(--color-w1);
          color: var(--color-p4);
          border-radius: 5px 5px 0 0;
          font-weight: 700;
        }

        h3 {
          color: var(--color-c3);
          font-size: 1rem;
          text-align: center;
          padding-bottom: 30px;
        }

        form {
          padding: 24px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          align-self: flex-start;
        

          input {
            padding: 14px;
            width: 100%;
            border: none;
            border-radius: 5px;
            outline-offset: 0px;
            outline: none;
            font-size: 1rem;
            color: var(--color-c3);
          }
          
          input::placeholder {
            color: var(--color-c3);
          }

        }
        button {
          margin-top: 24px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container-page--product {
    grid-template-columns: 1fr 300px;
    .left-side {
      .box-container--cta {
        flex-direction: column;
        display: flex;
        height: auto;
        
        .box-cta {
          align-items: center;
          justify-content: center;
          gap: 20px;
          flex-direction: row;

          h1 {
            font-size: 1.2rem;
            text-align: start;
          }

          p {
            text-align: start;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container-page--product {
    grid-template-columns: 1fr;
    gap: 30px;

    .left-side {
      .box-container--cta {
        flex-direction: row;

        .box-cta {
          flex-direction: column;

          h1 {
            text-align: center;
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container-page--product {
    .left-side {
      .info-product {
        h1 {
          font-size: 1.25rem;
          padding-bottom: 1rem;
        }

        .bradcumb {
          gap: 12px;
          align-items: center;
          a {
            font-size: 0.85rem;
          }
        }
      }
      .cta-info {
        flex-direction: column;

        p {
          text-align: center;
          padding-bottom: 24px;
        }

        button {
          display: flex;
        }
      }

      .box-container--cta {
        max-width: calc(100vw - 40px);
        overflow: auto;
        
        .box-cta {
          gap: 12px;
          min-width: 250px;
        }
      }
    }
  }
}