.slide-container {
  padding: 60px 20px 120px 20px;
  max-width: 1262px;
  margin: 0 auto;
}
@keyframes seta {
  from {
    margin-left: 8px;
  }
  to {
    margin-left: 30px;
    opacity: 0;
  }
}
.slide-container .drag {
  font-size: 1.2rem;
  padding-bottom: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--color-c3);
}
.slide-container .drag svg {
  fill: var(--color-p2);
  animation: seta 0.5s ease-in 0s infinite alternate;
}
.slide-container .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slide-container .info h3 {
  color: #407c6d;
  font-size: 1rem;
  padding: 4px;
  border-radius: 5px;
  background: #a7e7d7;
  margin-bottom: 90px;
}
.slide-container .info h1 {
  font-size: 2rem;
  line-height: 40px;
  color: var(--color-c4);
  padding-bottom: 14px;
  text-align: center;
}
.slide-container .info h1 span {
  color: var(--color-p3);
  font-weight: 800;
}
.slide-container .carousel {
  width: 100%;
  height: 360px;
  overflow: hidden;
  cursor: grab;
}
.slide-container .carousel .inner {
  display: flex;
  height: 100%;
  gap: 34px;
}

@media screen and (max-width: 600px) {
  .slide-container .drag {
    display: none;
  }
  .slide-container .carousel {
    height: 100%;
    overflow: hidden;
  }
  .slide-container .carousel .inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    flex-wrap: nowrap;
    gap: 12px;
    overflow: hidden;
  }
  .slide-container .info h1 {
    text-align: center;
    max-width: 440px;
  }
}/*# sourceMappingURL=styles.css.map */