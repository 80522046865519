footer {
  background: var(--color-p3);

  .top-side {
    padding: 120px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--color-w1);

    h1 {
      text-align: center;
      max-width: 600px;
      font-size: 3rem;
      color: var(--color-c4);
      padding-bottom: 30px;
    }
  }

  .bottom-side {
    max-width: 1262px;
    padding: 120px 20px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    

    h1 {
      color: var(--color-w2);
      font-size: 0.85rem;
      font-weight: normal;
      padding-bottom: 12px;
      span {
        font-weight: 700;
      }
    }

    #tel {
      padding-top: 12px;
    }

    p {
      padding-top: 30px;
      max-width: 250px;
    }

    
    .logo {
      grid-column: 1/2;
      transition: all 0.3s ease-in;
      
      .images-link {
        display: grid;
        grid-template-columns: 150px 150px;
      }
      
      img {
        padding-bottom: 30px;
      }

      p {
        color: var(--color-w1);
        padding-bottom: 12px;
      }

      img {
        margin-right: 12px;
        color: var(--color-w1);
        cursor: pointer;
        max-width: 200px;
        width: 100%;
      }
    }
    
    .exames-buscados, .consultas-buscadas, .saiba-mais {
      h1 {
        color: var(--color-w1);
        font-size: 1.25rem;
        font-weight: 600;
        padding-bottom: 24px;
      }

      h3 {
        color: red;
      }

      li, a {
        color: var(--color-w2);
        font-size: 1rem;
        padding-bottom: 12px;
        transition: all 0.3s ease-in-out;

        :hover {
          color: var(--color-w4);
        }
      }
    }

    .atendimento {
      grid-column: 2/3;
    }

    .menu {
      grid-column: 3/4;
    }
  }

  .info {
    max-width: 1262px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 20px;

    p {
      color: var(--color-w1);
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  footer {
    .bottom-side {
      grid-template-columns: 1fr 1fr;
      justify-content: center;

      .logo {
        padding-bottom: 60px;

        .images-link {
          justify-content: flex-start;
        }
      }

      .atendimento, .menu {
        padding-bottom: 30px;
      
        h1 {
          padding-bottom: 14px;
        }

        li {
          font-size: 1rem;
        }

      }
    }
  }
}

@media screen and (max-width: 800px) {
  footer {
    .top-side {
      h1 {
        font-size: 2rem;
        font-weight: normal;
      }
  
      button {
        background: var(--color-w1);
        color: var(--color-p3);
  
        :hover {
          background: var(--color-w2);
          color: var(--color-p4);
        }
      }
    }

    .bottom-side {
      width: 100%;
      grid-template-columns: 1fr;
      align-items: center;
      justify-content: center;
      margin: 0;

      h1 {
        text-align: center;
      }

      .logo {
        padding-bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .images-link {
          justify-content: center;
        }

        p {
          text-align: center;
        }

      }
    }
  }
}