@import './styles/colors.css';
@import './styles/typograph.css';

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background: rgba(10, 0, 0, 0.2);
  width: 3px;
  border-radius: 5px;
  opacity: 0.3;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

ul, li, a {
  text-decoration: none;
  list-style: none;
}

img {
  width: 100%;
}