.filter-container {
  max-width: 1262px;
  margin: 120px auto;
  padding: 0 20px;
  
  .filter-items {
    margin: 60px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    :hover {
      background: var(--color-w2);
    }
  }

  .titles {
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: center;
    position: relative;

    ::after {
      content: '';
      position: absolute;
      height: 4px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color:  #E5E5E5;
    }
    
    h1 {
      font-size: 1rem;
      color: var(--color-p2);
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    
    .title {
      display: flex;
      gap: 40px;
      align-items: center;
      justify-content: center;

      a {
        font-size: 20px;
        line-height: 32px;
        color: var(--color-c4);
        font-weight: 500;
        position: relative;
        padding-bottom: 12px;
        transition: all 0.3s ease-in-out;
      }

      a:hover::after {
        content: '';
        position: absolute;
        display: inline-block;
        height: 4px;
        width: 100%;
        border-radius: 5px;
        left: 0;
        bottom: 0;
        background: var(--color-p2);
        transition: all 0.3s ease-in-out;
        z-index: 1;
      }
    }
  }

  .filter {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;

    .filter-right {
      display: flex;
      gap: 20px;

      h1 {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    
    .filter-input {
      position: relative;
      
      input {
        min-width: 500px;
        padding: 12px 14px;
        border: 1px solid var(--color-w3);
        border-radius: 5px;
        outline-color: var(--color-w4);

        :hover {
          border: 1px solid var(--color-w4);
        }
      }

      input[type='text'],
      input[type='number'],
      textarea {
        font-size: 18px;
      }

    }
  }
}

@media screen and (max-width: 1125px) {
  .filter-container {
    .filter-items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

}

.filter {
  .filter-input {
    min-width: 300px;
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 965px) {
  .filter-container {
    max-width: 1262px;
    margin: 0 auto;

    .filter-items {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    
    .filter {
      width: 100%;
      .filter-right {
        display: none;
      }

      .filter-input {
        padding-top: 30px;
        width: 100%;
        
        input {
          min-width: 300px;
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .titles {
      display: flex;
      gap: 24px;
      flex-direction: column;
      align-items: center;

      ::after {
        content: '';
        background-color: transparent;
      }

      h1 {
        font-size: 1rem;
        color: var(--color-p2);
        text-transform: uppercase;
      }
      
      .title {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
  
        a {
          font-size: 16px;
          line-height: 32px;
          font-weight: 500;
          padding-bottom: 12px;
          padding: 10px;
          background: #FFCDE8;
          border-radius: 4px;
          color: var(--color-p4);
          font-weight: 600;
        }
      }
    }
  }

}

@media screen and (max-width: 600px) {
  .filter-container {
    padding: 20px;

    .filter-items {
      grid-template-columns: 1fr;
    }

    .titles {
      justify-content: start;
      align-items: start;
      overflow: auto;

      ::after {
        content: '';
      }
      
      .title {
        gap: 20px;
      }
    }

  }
}