:root {
  /*
    Gray colors
  */
  --color-white: #FFFFFF;
  --color-c1: #7E838C;
  --color-c2: #636973;
  --color-c3: #474D59;
  --color-c4: #363B47;
  --color-c5: #242833;
  --color-c6: #0B0C12;

  /*
    White colors
  */
  --color-w1: #F5F7FA;
  --color-w2: #E6EAF0;
  --color-w3: #CED3D9;
  --color-w4: #C0C5CC;
  --color-w5: #B2B8BF;
  --color-w6: #989EA6;

  /*
    red colors
  */
  --color-p1: #F3333E;
  --color-p2: #D4131E;
  --color-p3: #BA0611;
  --color-p4: #A4000A;
}