.button {
  display: inline-block;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 32px;
  border: none;
  text-transform: uppercase;
  background: linear-gradient(180deg, #E20613 0%, #E20613 100%);
  border-radius: 5px;
  color: var(--color-w1);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 0.1em;
}