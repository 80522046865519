.box-filter {
  padding: 12px 24px;
  border-radius: 10px;
  max-width: 290px;
  width: 100%;
  cursor: pointer;
  position: relative;

  h1 {
    font-size: 1.25rem;
    color: var(--color-c4);
    line-height: 2rem;
  }

  svg {
    color: var(--color-c4);
  }

  .box-filter--info {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .price {
    h1 {
      color: var(--color-p2);
      font-size: 1rem;
    }
  }
}

.box-filter::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background: var(--color-p2);
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .box-filter {
    max-width: 100%;
  }
}