.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 20px;
  max-width: 1262px;
}
.header-container .left-side {
  max-width: 250px;
}
.header-container .right-side .hidden, .header-container .right-side .showed {
  display: none;
  color: var(--color-w4);
}
.header-container .right-side nav ul {
  display: flex;
  gap: 40px;
  align-items: center;
}
.header-container .right-side nav ul li {
  position: relative;
}
.header-container .right-side nav ul li a {
  display: flex;
  align-items: center;
  color: var(--color-c3);
  font-weight: 500;
}
.header-container .right-side nav ul #icon img, .header-container .right-side nav ul #icon svg {
  color: var(--color-p2);
}
.header-container .right-side nav ul .menu {
  position: absolute;
  right: 0;

  top: 40px;
  border-radius: 5px;
  background: var(--color-w1);
  display: none;
  transition: all 0.3s ease-in;
  z-index: 10;
  
}
.header-container .right-side nav ul .menu a {
  padding: 8px 12px;
  border-radius: 4px;
  width: 250px;
}
.header-container .right-side nav ul .menu :hover {
  background: var(--color-p2);
  color: var(--color-w1);
}
.header-container .right-side nav ul #active {
  display: inline-block;
}
.header-container .right-side #menu-icon {
  display: none;
}

.blog {
  color: #e20612;
}

@media screen and (max-width: 768px) {
  .header-container {
    position: relative;
  }
  .header-container .left-side img {
    max-width: 200px;
  }
  .header-container .right-side nav {
    transition: all 0.3s ease-in;
  }
  .header-container .right-side nav ul {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header-container .right-side nav .active {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: none;
    z-index: 9;
    background: var(--color-p3);
    z-index: 80;
    color: var(--color-w1);
  }
  .header-container .right-side nav .active li a {
    color: var(--color-w1);
    padding: 6px 12px;
    border-radius: 4px;
    background: var(--color-p4);
  }
  .header-container .right-side nav .active .menu {
    background: var(--color-p4);
    margin: 0 auto;
  }
  .header-container .right-side nav .hidden {
    display: none;
  }
  .header-container .right-side nav .showed {
    display: inline-block;
    position: absolute;
    top: 30px;
    right: 20px;
  }
  .header-container .right-side nav #menu-icon {
    display: inline-block;
    z-index: 99;
  }
}/*# sourceMappingURL=styles.css.map */