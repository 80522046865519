.bg-container {
  background: var(--color-w1);
  box-shadow: inset 0 40px white, inset 0 -40px white;
}

.about-container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  max-width: 1262px;
  margin: 0 auto;
  padding: 120px 20px;
}
.about-container .left-side {
  grid-column: 1/2;
  display: flex;
}
.about-container .left-side img {
  width: 100%;
  align-self: start;
}
.about-container .left-side ::after {
  content: "";
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  width: 30px;
  height: 4px;
  background: var(--color-p3);
}
.about-container .right-side {
  grid-column: 2/4;
}
.about-container .right-side h1 {
  font-size: 3rem;
  line-height: 3.5rem;
  color: var(--color-c3);
  font-weight: 800;
  padding-bottom: 30px;
}
.about-container .right-side h1 span {
  color: var(--color-p2);
}
.about-container .right-side p {
  font-size: 18px;
  line-height: 28px;
  color: var(--color-c3);
  padding-bottom: 12px;
}
.about-container .right-side p span {
  font-weight: 800;
}
.about-container .right-side .info-about--container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 34px;
  background: var(--color-white);
  border-radius: 5px;
  gap: 32px;
  margin-top: 30px;
}
.about-container .right-side .info-about--container .box {
  width: calc(50% - 32px);
}
.about-container .right-side .info-about--container .box h1 {
  font-size: 18px;
  line-height: 120%;
  padding-bottom: 8px;
}
.about-container .right-side .info-about--container .box p {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-c3);
  max-width: 217px;
  padding-bottom: 0;
}
.about-container .right-side .info-about--container .box svg {
  color: var(--color-p2);
}

@media screen and (max-width: 1240px) {
  .bg-container {
    box-shadow: 0 0 0, 0 0 0;
    padding: 20px 0px;
  }
  .about-container {
    grid-template-columns: minmax(300px, 400px) 1fr;
    gap: 20px;
    padding: 60px 20px;
  }
  .about-container .left-side {
    grid-column: 1/2;
  }
  .about-container .right-side {
    grid-column: 2/4;
  }
  .about-container .right-side h1 {
    padding-bottom: 24px;
    font-size: 40px;
    line-height: 120%;
  }
  .about-container .right-side p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media screen and (max-width: 992px) {
  .about-container {
    grid-template-columns: 1fr;
  }
  .about-container .left-side, .about-container .right-side {
    grid-column: 1/2;
  }
  .about-container .left-side {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-container .left-side img {
    max-width: 50%;
    border-radius: 5px;
    -o-object-fit: cover;
       object-fit: cover;
    padding-bottom: 24px;
  }
  .about-container .right-side {
    flex-wrap: wrap;
  }
  .about-container .right-side h1, .about-container .right-side p {
    text-align: center;
  }
  .about-container .right-side .info-about--container {
    gap: 14;
  }
  .about-container .right-side .info-about--container .box {
    width: 45%;
  }
  .about-container .right-side .info-about--container .box h1, .about-container .right-side .info-about--container .box p {
    text-align: start;
  }
}
@media screen and (max-width: 450px) {
  .about-container .left-side {
    width: 100%;
  }
  .about-container .right-side .info-about--container .box {
    width: 100%;
  }
}/*# sourceMappingURL=styles.css.map */