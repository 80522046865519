.filter-container {
  max-width: 1262px;
  margin: 120px auto;
  padding: 0 20px;
}
.filter-container .filter-items {
  margin: 60px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}
.filter-container .filter-items :hover {
  background: var(--color-w2);
}
.filter-container .titles {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.filter-container .titles ::after {
  content: "";
  position: absolute;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #E5E5E5;
}
.filter-container .titles h1 {
  font-size: 1rem;
  color: var(--color-p2);
  text-transform: uppercase;
  margin-bottom: 30px;
}
.filter-container .titles .title {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
}
.filter-container .titles .title a {
  font-size: 20px;
  line-height: 32px;
  color: var(--color-c4);
  font-weight: 500;
  position: relative;
  padding-bottom: 12px;
  transition: all 0.3s ease-in-out;
}
.filter-container .titles .title a:hover::after {
  content: "";
  position: absolute;
  display: inline-block;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  left: 0;
  bottom: 0;
  background: var(--color-p2);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.filter-container .filter {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
}
.filter-container .filter .filter-right {
  display: flex;
  gap: 20px;
}
.filter-container .filter .filter-right h1 {
  font-size: 1rem;
  font-weight: 400;
}
.filter-container .filter .filter-input {
  position: relative;
}
.filter-container .filter .filter-input input {
  min-width: 500px;
  padding: 12px 14px;
  border: 1px solid var(--color-w3);
  border-radius: 5px;
  outline-color: var(--color-w4);
}
.filter-container .filter .filter-input input :hover {
  border: 1px solid var(--color-w4);
}
.filter-container .filter .filter-input input[type=text],
.filter-container .filter .filter-input input[type=number],
.filter-container .filter .filter-input textarea {
  font-size: 18px;
}

@media screen and (max-width: 1125px) {
  .filter-container .filter-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.filter .filter-input {
  min-width: 300px;
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 965px) {
  .filter-container {
    max-width: 1262px;
    margin: 0 auto;
  }
  .filter-container .filter-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .filter-container .filter {
    width: 100%;
  }
  .filter-container .filter .filter-right {
    display: none;
  }
  .filter-container .filter .filter-input {
    padding-top: 30px;
    width: 100%;
  }
  .filter-container .filter .filter-input input {
    min-width: 300px;
    width: 100%;
    max-width: 100%;
  }
  .filter-container .titles {
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: center;
  }
  .filter-container .titles ::after {
    content: "";
    background-color: transparent;
  }
  .filter-container .titles h1 {
    font-size: 1rem;
    color: var(--color-p2);
    text-transform: uppercase;
  }
  .filter-container .titles .title {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
  .filter-container .titles .title a {
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    padding-bottom: 12px;
    padding: 10px;
    background: #FFCDE8;
    border-radius: 4px;
    color: var(--color-p4);
    font-weight: 600;
  }
}
@media screen and (max-width: 600px) {
  .filter-container {
    padding: 20px;
  }
  .filter-container .filter-items {
    grid-template-columns: 1fr;
  }
  .filter-container .titles {
    justify-content: start;
    align-items: start;
    overflow: auto;
  }
  .filter-container .titles ::after {
    content: "";
  }
  .filter-container .titles .title {
    gap: 20px;
  }
}/*# sourceMappingURL=styles.css.map */