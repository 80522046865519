footer {
  background: var(--color-p3);
}
footer .top-side {
  padding: 120px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-w1);
}
footer .top-side h1 {
  text-align: center;
  max-width: 600px;
  font-size: 3rem;
  color: var(--color-c4);
  padding-bottom: 30px;
}
footer .bottom-side {
  max-width: 1262px;
  padding: 120px 20px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}
footer .bottom-side h1 {
  color: var(--color-w2);
  font-size: 0.85rem;
  font-weight: normal;
  padding-bottom: 12px;
}
footer .bottom-side h1 span {
  font-weight: 700;
}
footer .bottom-side #tel {
  padding-top: 12px;
}
footer .bottom-side p {
  padding-top: 30px;
  max-width: 250px;
}
footer .bottom-side .logo {
  grid-column: 1/2;
  transition: all 0.3s ease-in;
}
footer .bottom-side .logo .images-link {
  display: grid;
  grid-template-columns: 150px 150px;
}
footer .bottom-side .logo img {
  padding-bottom: 30px;
}
footer .bottom-side .logo p {
  color: var(--color-w1);
  padding-bottom: 12px;
}
footer .bottom-side .logo img {
  margin-right: 12px;
  color: var(--color-w1);
  cursor: pointer;
  max-width: 200px;
  width: 100%;
}
footer .bottom-side .exames-buscados h1, footer .bottom-side .consultas-buscadas h1, footer .bottom-side .saiba-mais h1 {
  color: var(--color-w1);
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 24px;
}
footer .bottom-side .exames-buscados h3, footer .bottom-side .consultas-buscadas h3, footer .bottom-side .saiba-mais h3 {
  color: red;
}
footer .bottom-side .exames-buscados li, footer .bottom-side .exames-buscados a, footer .bottom-side .consultas-buscadas li, footer .bottom-side .consultas-buscadas a, footer .bottom-side .saiba-mais li, footer .bottom-side .saiba-mais a {
  color: var(--color-w2);
  font-size: 1rem;
  padding-bottom: 12px;
  transition: all 0.3s ease-in-out;
}
footer .bottom-side .exames-buscados li :hover, footer .bottom-side .exames-buscados a :hover, footer .bottom-side .consultas-buscadas li :hover, footer .bottom-side .consultas-buscadas a :hover, footer .bottom-side .saiba-mais li :hover, footer .bottom-side .saiba-mais a :hover {
  color: var(--color-w4);
}
footer .bottom-side .atendimento {
  grid-column: 2/3;
}
footer .bottom-side .menu {
  grid-column: 3/4;
}
footer .info {
  max-width: 1262px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 20px;
}
footer .info p {
  color: var(--color-w1);
  font-size: 1rem;
  line-height: 1.5rem;
}

@media screen and (max-width: 1200px) {
  footer .bottom-side {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
  footer .bottom-side .logo {
    padding-bottom: 60px;
  }
  footer .bottom-side .logo .images-link {
    justify-content: flex-start;
  }
  footer .bottom-side .atendimento, footer .bottom-side .menu {
    padding-bottom: 30px;
  }
  footer .bottom-side .atendimento h1, footer .bottom-side .menu h1 {
    padding-bottom: 14px;
  }
  footer .bottom-side .atendimento li, footer .bottom-side .menu li {
    font-size: 1rem;
  }
}
@media screen and (max-width: 800px) {
  footer .top-side h1 {
    font-size: 2rem;
    font-weight: normal;
  }
  footer .top-side button {
    background: var(--color-w1);
    color: var(--color-p3);
  }
  
  footer .top-side button :hover {
    background: var(--color-w2);
    color: var(--color-p4);
  }

  footer .bottom-side {
    width: 100%;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  footer .bottom-side .logo {
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  footer .bottom-side .logo .images-link {
    justify-content: center;
  }
  footer .bottom-side .logo p {
    text-align: center;
  }


}/*# sourceMappingURL=styles.css.map */  

.whatsapp-flutuante {
  position: fixed;
  bottom: 40px; /* Distância do ícone até o fundo da página */
  right: 40px; /* Distância do ícone até a borda direita da página */
  z-index: 1000; /* Garante que o ícone fique acima de outros elementos na página */
}

.logoWhatsapp {
  width: 80px; /* Ajuste o tamanho conforme necessário */
  height: 80px; /* Ajuste o tamanho conforme necessário */
  /* Outros estilos conforme necessário */
}

.logoWhatsapp:hover {
  transform: scale(1.1); /* Aumenta ligeiramente o tamanho do ícone ao passar o mouse */

}