.changed-page--container {
  max-width: 1262px;
  margin: 60px auto;
  padding: 0 20px;

  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 300px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    border-radius: 5px;

    h1 {
      color: var(--color-w1);
      font-weight: 800;
      font-size: 3rem;
      padding-bottom: 30px;
    }

    p {
      color: var(--color-w2);
      font-size: 1.25rem;
      
      span {
        font-weight: 800;
      }
    }

    img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      z-index: -1;
      border-radius: 5px;
    }
  }

}

@media screen and (max-width: 950px) {
  .changed-page--container {
    .banner {
      padding: 30px 40px;
      border-radius: 5px;

      h1 {
        font-size: 2rem;
        padding-bottom: 12px;
        text-align: center;
      }

      p {
        font-size: 1.2rem;
        text-align: center;
      }

      img {
        height: 300px;
        border-radius: 5px;
      }
    }
  }
}