.testimonial-container {
  max-width: 1262px;
  margin: 120px auto;
  padding: 0 20px;
}
.testimonial-container .info-side h3 {
  color: var(--color-p2);
  font-size: 1rem;
  text-transform: uppercase;
  padding-bottom: 18px;
}
.testimonial-container .info-side h1 {
  font-size: 2rem;
  line-height: 120%;
  color: var(--color-c4);
  position: relative;
  padding-bottom: 90px;
}
.testimonial-container .testimonial-side {
  display: grid;
  grid-template-columns: minmax(190px, 290px) 1fr;
  gap: 50px;
}
.testimonial-container .testimonial-side .left-side {
  grid-column: 1/2;
}
.testimonial-container .testimonial-side .left-side .people-testimonial {
  transition: all 0.6s ease-in-out;
}
.testimonial-container .testimonial-side .left-side .people-testimonial :hover {
  background: var(--color-w1);
}
.testimonial-container .testimonial-side .left-side .people-testimonial .people-info-container {
  display: flex;
  align-items: center;
  padding: 24px;
  justify-content: center;
  gap: 16px;
  margin-bottom: 4px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.testimonial-container .testimonial-side .left-side .people-testimonial .people-info-container .people-image {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background: var(--color-p1);
}
.testimonial-container .testimonial-side .left-side .people-testimonial .people-info-container h1 {
  font-size: 18px;
  color: var(--color-c4);
  font-weight: 500;
}
.testimonial-container .testimonial-side .left-side .people-testimonial .people-info-container p {
  font-size: 1rem;
  color: var(--color-c3);
  font-weight: 500;
}
.testimonial-container .testimonial-side .right-side {
  grid-column: 2/4;
  padding: 50px;
  background: var(--color-w1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}
.testimonial-container .testimonial-side .right-side .mobile-name {
  display: none;
}
.testimonial-container .testimonial-side .right-side .comment-testimonial .quote {
  position: absolute;
  right: 24px;
  top: 40px;
}
.testimonial-container .testimonial-side .right-side .comment-testimonial h1 {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  padding-bottom: 60px;
  font-weight: normal;
}
.testimonial-container .testimonial-side .right-side .comment-testimonial h1 img {
  max-width: 24px;
}
.testimonial-container .testimonial-side .right-side .comment-testimonial p {
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: var(--color-c3);
}

@media screen and (max-width: 992px) {
  .testimonial-container .info-side h3 {
    text-align: center;
    color: var(--color-p2);
    font-size: 0.75rem;
    text-transform: uppercase;
    padding-bottom: 12px;
  }
  .testimonial-container .info-side h1 {
    font-size: 1.5rem;
    line-height: 120%;
    color: var(--color-c4);
    position: relative;
    padding-bottom: 60px;
    text-align: center;
  }
  .testimonial-container .testimonial-side {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .testimonial-container .testimonial-side .left-side {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
  }
  .testimonial-container .testimonial-side .left-side .people-testimonial {
    display: flex;
    gap: 20px;
  }
  .testimonial-container .testimonial-side .left-side .people-testimonial .people-info-container {
    display: flex;
    margin-bottom: 30px;
    padding: 0;
    gap: 0;
    justify-content: center;
  }
  .testimonial-container .testimonial-side .left-side .people-testimonial .people-info-container h1 {
    display: none;
  }
  .testimonial-container .testimonial-side .left-side .people-testimonial .people-info-container p {
    display: none;
  }
  .testimonial-container .testimonial-side .right-side .mobile-name {
    display: inline-block;
    padding-bottom: 12px;
    font-weight: 500;
  }
  .testimonial-container .testimonial-side .right-side .comment-testimonial p {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  .testimonial-container .testimonial-side .right-side .comment-testimonial .quote {
    top: 50px;
    right: 50px;
    max-width: 40px;
  }
}/*# sourceMappingURL=styles.css.map */